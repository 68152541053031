@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 13%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 216, 3%, 63%;

    --primary: 0 0% 13%;
    --primary-foreground: 0 0% 100%;

    --secondary: 0 0% 100%;
    --secondary-foreground: 0 0% 13%;

    --accent: 71 82% 63%;
    --accent-foreground: 0 0% 13%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --radius: 0.6rem;


    --slider-padding: 1rem;
    --slider-column-gap: 1rem;
    --slide-width: 25%;
    --slide-min-width: 360px;
  }

  html {
    @apply bg-myNight;
    overflow-x: hidden;
  }

  body {
    @apply text-base;
    @apply text-white;
    @apply bg-darkNightToNight;
    min-width: 375px;
    text-rendering: geometricPrecision;
    overflow-x: hidden;
  }

  a {
    text-decoration: none;
    transition: color 0.25s linear;
  }

  a:hover {
    @apply text-myYellow;
  }

  p > a {
    text-decoration: underline;
  }

  p > a:hover {
    @apply text-myBlue;
  }

  .font-handlee strong {
    font-weight: 300;
    @apply font-wendy
  }
}

.oi-partner-logo img{
  max-height: 60px;
  width: auto;
}

@screen sm {
  .oi-partner-logo img{
    max-height: 80px;
  }
}

.font-wendy {
  text-rendering: geometricPrecision;
}

.oi-partner-logo {
  display: inline-block;
  padding: 0 18px;
}

.oi-partner-logo-rotary a {
  display: block;
}

.oi-partner-logo-rotary span {
  display: none;
}

@screen sm {
  .oi-partner-logo-rotary a,
  .oi-partner-logo-rotary span {
    display: inline-block;
  }
}

.flex-item {
  flex: 1 1 calc(50%);
  max-width: calc(50%);
  @apply aspect-square overflow-hidden
}

@screen xs {
  .oi-gallery-teaser .flex-item:last-child {
    flex: 1 1 calc(100%);
    max-width: calc(100%);
  }
}

@screen sm {
  .flex-item,
  .flex-item:last-child {
    flex: 1 1 calc(33.333%);
    max-width: calc(33.333%);
    @apply aspect-square overflow-hidden
  }

  .oi-gallery-teaser .flex-item:nth-child(5n + 1),
  .oi-gallery-teaser .flex-item:nth-child(5n + 2) {
    flex: 1 1 calc(50% - 1px);
    max-width: calc(50% - 1px);
  }
}


@screen xs {
  .oi-gallery .flex-item:first-child {
    flex: 1 1 calc(100%);
    max-width: calc(100%);
  }
}


.header-placeholder {
  min-height: 100px;
}

.button-border {
  border: 3px solid #32376D;
}

.button-border:hover {
  border-color: #1D224B;
}

.slider {
  overflow: hidden;
}

.slider__track {
  display: flex;
  overflow-x: auto;
  -ms-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;
  padding-inline: var(--slider-padding);
  scroll-behavior: smooth;
  list-style-type: none;
  padding: 0;
  margin-right: calc(var(--slider-column-gap)* -1);
  scrollbar-width: none;
}

.slider__track > * {
  flex: 0 0 var(--slide-width);
  min-width: var(--slide-min-width);
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

@layer utilities {
  .backdrop-blur {
    --tw-backdrop-blur: 10px;
  }
}

.snap-x {
  scroll-snap-type: x mandatory;
}

.snap-center {
  scroll-snap-align: center;
}

.logo {
  display: inline-block;
  width: 100%;
  max-width: 240px;
  aspect-ratio: 240 / 70;
  background-image: url(../assets/logo.svg);
}


.footer-animals {
  display: inline-block;
  width: 100%;
  height: 100px;
  max-height: 9.5vw;
  background-image: url(../assets/footer-animals.svg);
  background-size: auto 100%;
  background-position: center bottom;
}

/* Hamburger -> X */
#toggle-menu.is-open .hamburger {
  visibility: hidden;
}
#toggle-menu.is-open .hamburger:before {
  visibility: visible;
  transform: rotate(45deg);
}
#toggle-menu.is-open .hamburger:after {
  visibility: visible;
  transform: rotate(-45deg);
}

#toggle-menu.is-open .hamburger {
  visibility: hidden;
}

#toggle-menu.is-open .hamburger:before {
  visibility: visible;
  transform: rotate(45deg);
}
#toggle-menu.is-open .hamburger:after {
  visibility: visible;
  transform: rotate(-45deg);
}

/* Mobile Navigation */
@screen mobile {
  #main-navigation {
    z-index: 1;
  }

  #main-navigation {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    width: 100%;
    height: 100px;
    transition: transform 0.25s linear, height 0.25s linear 0s;
  }

  #main-navigation.is-open {
    height: 100vh;
    transform: none!important;
    transition: height 0.25s linear;
  }

  #main-navigation .container {
    @apply bg-myBgNight
  }

  #main-navigation .logo {
    position: absolute;
    top: 14px;
    left: 14px;
  }

  #main-navigation nav {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0s ease 0s, visibility 0s ease 0.5s;
  }

  #main-navigation.is-open nav {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.25s ease 0.25s, visibility 0s ease 0s;
  }

  #toggle-menu {
    position: absolute;
    top: 30px;
    right: 30px;
  }
}

/* Layout classes */
.oi-section {
  @apply mb-3xl -mt-lg
}

.oi-component {
}

/* Button */
.oi-button {
  @apply mb-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded inline-block no-underline
}

.oi-button-primary {
  @apply font-wendy text-sm px-lg py-sm leading-6 transition rounded-full p-md button-border bg-myNightLighter text-white hover:text-myNight hover:bg-transparent cursor-pointer
}

.oi-button-secondary {
  @apply font-wendy text-sm px-lg py-sm transition border-3 rounded-full p-md border-current text-white hover:text-myYellow cursor-pointer
}

/* Form Elements */
.oi-input-text {
  @apply block w-full px-sm text-sm bg-transparent border-0 border-b-3 border-myNight focus:bg-white;
}

form input[type=text],
form input[type=email],
form textarea {
  @apply -mb-lg;
}

form label {
  @apply block text-right -mt-md text-xs;
}

form label > a {
  @apply underline;
}

footer p > a:hover,
form label a:hover {
  @apply text-myBlue;
}

form input[type=text]:focus + label,
form input[type=email]:focus + label,
form textarea:focus + label,
form input[type=text]:not(:placeholder-shown) + label,
form input[type=email]:not(:placeholder-shown) + label,
form textarea:not(:placeholder-shown) + label {
  @apply mt-lg -mb-lg;
}

form input[type=text]:focus,
form input[type=email]:focus,
form textarea:focus,
form input[type=text]:not(:placeholder-shown),
form input[type=email]:not(:placeholder-shown),
form textarea:not(:placeholder-shown) {
  @apply bg-myBgOffWhite;
}

form input[type=checkbox] + label {
  @apply text-sm text-left cursor-pointer ml-xl
}

form input[type=checkbox] + label::before {
  content: "✔";
  width: 18px;
  height: 18px;
  margin-right: 6px;
  font-size: 0.8em;
  line-height: 1em;
  @apply inline-block font-wendy -ml-xl text-transparent border-3 border-myNightLighter rounded-xs;
}

form input[type=checkbox]:checked + label::before {
  @apply text-white
}

form input[type=checkbox]:checked + label::before {
  @apply bg-myNightLighter;
}

.cookie-consent input[type=checkbox] {
  position: absolute;
  visibility: hidden;
}

.cookie-consent input[type=checkbox] + label {
  @apply text-sm text-left cursor-pointer ml-xl
}

.cookie-consent input[type=checkbox] + label::before {
  content: "✔";
  top: 0;
  width: 18px;
  height: 18px;
  margin-right: 6px;
  font-size: 0.8em;
  line-height: 1em;
  @apply inline-block font-wendy -ml-xl text-transparent border-3 border-white rounded-xs;
}

.cookie-consent input[type=checkbox]:checked + label::before {
  @apply text-myNight
}

.cookie-consent input[type=checkbox]:checked + label::before {
  @apply bg-white;
}

.cookie-consent input[type=checkbox]:checked:disabled + label::before {
  @apply bg-gray-300;
}

.cookie-consent input[type=checkbox]:disabled + label::before {
  @apply border-gray-300;
}

.cookie-consent input[type=checkbox]:disabled + label {
  @apply cursor-default;
}

dfn {
  font-style: normal;
}

.oi-input-textarea {
  @apply oi-input-text;
}
.oi-select {
  @apply oi-input-text;
}
.oi-checkbox {
  @apply text-gray-600
}
.oi-radiobutton {
  @apply oi-checkbox
}

/* Default styles */

h1, h2, h3 {
  margin-top: -0.15em;
  hyphens: auto;
}
/* Headings */
h1 {
  @apply text-xl mb-lg;
}
h2 {
  @apply text-lg mb-lg;
}
h3 {
  @apply text-md mb-lg;
}
p {
  @apply text-base;
}
p:not(:last-child) {
  @apply  mb-lg;
}

/* Kontakt Modal */

/* Add animation (Chrome, Safari, Opera) */

@-webkit-keyframes slideIn {
  from {
    top: -100px;
    opacity: 0;
  }
  to {
    top: 0px;
    opacity: 1;
  }
}
/* Add animation (Standard syntax) */

@keyframes slideIn {
  from {
    transform: translateY(-100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
/* The modal's background */

.modal {
  position: fixed;
  display: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 2;
  @apply bg-myBgNight
}

@screen mobile {
  .modal .container {
    @apply p-lg
  }
}
/* Display the modal when targeted */

.modal:target,
.modal.is-open {
  display: table;
}

@screen mobile {
  .modal.scrollable:target {
    display: block;
    @apply pb-2xl
  }
}
/* The modal box */
.modal-backdrop {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

/* The modal's content */

.modal-inner {
  margin: auto;
  @apply rounded-sm rounded-br-lg bg-whiteToDay text-myNight;
}

@media (min-device-width: 767px) {
  .modal.is-open .modal-inner,
  .modal:target .modal-inner {
    animation-name: slideIn;
    animation-duration: 0.5s;
  }
}

.icon-btn {
  @apply inline-block bg-myNightLighter rounded-full text-white font-rubik text-xl sm:text-lg not-italic aspect-square !leading-[0.95em] indent-px w-[1em]
}

fieldset.optional > .js-fieldset-plus,
fieldset.optional > .js-fieldset-minus,
fieldset.optional.expanded > .js-fieldset-plus {
  display: none;
}

fieldset.optional:not(expanded) > .js-fieldset-plus,
fieldset.expanded > .js-fieldset-minus {
  display: inline-block;
}

fieldset.optional {
  display: none;
}

h3 + fieldset.optional,
fieldset:not(.optional) + fieldset.optional,
fieldset.expanded + fieldset.optional {
  display: flex;
}

/* Add this to your CSS file */
html.modal-open,
html.modal-open body {
  overflow: hidden;
}

.modal {
  opacity: 0;
  visibility: hidden;
}

.modal:target,
.modal.is-open {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.25s linear 0.15s;
}

.js-profil-button,
.modal.fancybox .js-profil-text {
  display: block;
}

.js-profil-text,
.modal.fancybox .js-profil-button {
  display: none;
}

.modal.fancybox .js-profil-grid {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.modal.fancybox .modal-inner {
  border-radius: 16px;
}

@screen sm {
  .modal.fancybox .js-profil-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 24px;
  }
}

.modal.fancybox img {
  display: block;
  opacity: 1;
}

.modal img {
  opacity: 0;
  transition: opacity 0.5s linear;
}

.modal.gallery.is-open img {
  opacity: 1;
}

.modal button#galleryPrev {
  transform: translateX(-100px);
  transition: transform 0.25s linear;
}

.modal button#galleryNext {
  transform: translateX(100px);
}

.modal.is-open button#galleryPrev,
.modal.is-open button#galleryNext {
  transform: translateX(0px);
}
